import { createAction, createReducer } from '@reduxjs/toolkit';
import {
  BillingStatus,
  MeetingKit,
  SearchStatus,
  Team,
  TeamUpgradeOption,
  UserSettingsAutosaveStatus,
  UserSettingsAutosaveTimestampOption,
  UserSettingsNotificationsMeetingsChat,
  UserSettingsNotificationsTranscriptEmails,
  UserSettingsNotificationsTranscriptExtension,
  UserSettingsSharingParticipants,
  UserSpace,
  UserTier,
  UserUpdatesSubscription,
} from '../../graphql/operations';
import { identifyUser, setAnalyticsTeamId } from '../../helpers/analytics';

export const updateUserDataFromBackend = createAction<
  UserUpdatesSubscription['user']
>('UPDATE_USER_DATA_FROM_BACKEND');

export const gotTeam = createAction<Team | undefined>('GOT_TEAM');

export const updateBasicDetails = createAction<{
  uid: string;
  displayName: string;
  email: string;
  photoURL?: string;
}>('UPDATE_BASIC_DETAILS');

export const clearUserData = createAction('CLEAR_USER_DATA');
export const signOut = createAction('SIGN_OUT');

export const updateLastRenewedDate = createAction<number>(
  'UPDATE_LAST_RENEWED_DATE'
);

export const gotDomainUsersCount = createAction<number>(
  'GOT_DOMAIN_USERS_COUNT'
);

export const updateUserSpacesList =
  createAction<UserSpace[]>('UPDATE_USER_SPACES');

const defaultState: UserUpdatesSubscription['user'] & {
  domainUsersCount: number;
} = {
  buildVersion: '',

  id: '',
  displayName: '',
  email: '',
  domain: '',
  isFromPrivateDomain: false,
  lastTeamId: '',
  lastMeetingTimestamp: 0,
  isPaid: false,
  billingStatus: BillingStatus.FREE,
  tier: UserTier.FREE,
  teamUpgradeOption: TeamUpgradeOption.ALREADY_ON_TEAM_PLAN,

  pricing: {
    individual: {
      currency: 'US$',
      monthlyID: '',
      monthlyIDPayPal: '',
      monthlyAmount: 12,
      annualID: '',
      annualIDPayPal: '',
      annualAmount: 96,
    },
    team: {
      currency: 'US$',
      monthlyID: '',
      monthlyAmount: 12,
      annualID: '',
      annualAmount: 96,
    },
  },

  team: undefined,
  spaces: [],

  shares: [],

  waitlists: [],

  onboarding: {
    GoogleMeet: {
      isSelected: false,
      isMeetingCompleted: false,
    },
    Zoom: {
      isSelected: false,
      isMeetingCompleted: false,
    },
    Teams: {
      isSelected: false,
      isMeetingCompleted: false,
    },
    Webex: {
      isSelected: false,
      isMeetingCompleted: false,
    },
  },
  domainUsersCount: 0,
  settings: {
    addTactiqLinksToCalendarEvents: false,
    aiFeaturesEnabled: false,
    autoPauseEnabled: false,
    autoSaveOptions: {
      __typename: undefined,
      timestampOption: UserSettingsAutosaveTimestampOption.DURATION,
    },
    autoSaveStatus: UserSettingsAutosaveStatus.OFF,
    enableLLM: false,
    aiOutputLanguage: 'en',
    notifications: {
      __typename: undefined,
      meetings: {
        __typename: undefined,
        chat: UserSettingsNotificationsMeetingsChat.AUTO_SHARE,
      },
      transcripts: {
        __typename: undefined,
        emails: UserSettingsNotificationsTranscriptEmails.DISABLED,
        extension: UserSettingsNotificationsTranscriptExtension.DISABLED,
      },
    },
    privacy: {
      __typename: undefined,
      hideNames: false,
      localFields: [],
    },
    requestedDeletion: 0,
    searchEnabled: false,
    sharing: {
      __typename: undefined,
      participants: UserSettingsSharingParticipants.AUTO,
    },
    tags: [],
  },
  createdAt: Date.now(),

  plan: {
    free: {
      allowance: 10,
      used: 0,
      lastRenewDate: Date.now(),
      aiCredits: {
        used: 0,
        allowance: 25,
      },
    },
  },

  hasGoogleCalendar: false,
  hasGoogleWorkspaceAddon: false,

  searchStatus: SearchStatus.DISABLED,

  meetingKits: { system: {} as MeetingKit, explore: [], used: [] },
};

export default createReducer(defaultState, (builder) => {
  builder
    .addCase(updateBasicDetails, (state, { payload }) => {
      if (state.id !== payload.uid) {
        identifyUser(payload.uid, payload.email, payload.displayName);
      }

      state.id = payload.uid;
      state.displayName = payload.displayName;
      state.email = payload.email;
      state.photoURL = payload.photoURL;
    })
    .addCase(updateUserDataFromBackend, (state, { payload }) => {
      if (payload?.team?.id && payload.team?.id !== state?.team?.id) {
        setAnalyticsTeamId(payload.team.id);
      }

      Object.assign(state, payload);
    })
    .addCase(gotTeam, (state, { payload }) => {
      if (payload) {
        state.team = payload;
      }
    })
    .addCase(signOut, (state) => {
      Object.assign(state, defaultState);
    })
    .addCase(clearUserData, (state) => {
      Object.assign(state, defaultState);
    })
    .addCase(gotDomainUsersCount, (state, { payload }) => {
      state.domainUsersCount = payload;
    })
    .addCase(updateLastRenewedDate, (state, { payload }) => {
      state.plan.free.lastRenewDate = payload;
    })
    .addCase(updateUserSpacesList, (state, { payload }) => {
      state.spaces = payload;
    });
});
